/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ChevronLeft } from "../../icons/ChevronLeft";
import { AtomsIconsSystem } from "../AtomsIconsSystem";
import "./style.css";

interface Props {
  type: "textlink" | "backlink";
  className: any;
  text: string;
  atomsIconsSystemAtomsIconsSystem: string;
}

export const Link = ({
  type,
  className,
  text = "Entdecken Sie den PRO-Account",
  atomsIconsSystemAtomsIconsSystem = "/img/atoms-icons-system-chevron-down-3.svg",
}: Props): JSX.Element => {
  return (
    <div className={`link ${className}`}>
      {type === "backlink" && <ChevronLeft className="chevron-left" />}

      <div className="div-3">
        {type === "backlink" && <>Zurück</>}

        {type === "textlink" && <>{text}</>}
      </div>
      {type === "textlink" && (
        <AtomsIconsSystem
          atomsIconsSystem={atomsIconsSystemAtomsIconsSystem}
          className="atoms-icons-system-chevron-down"
        />
      )}
    </div>
  );
};

Link.propTypes = {
  type: PropTypes.oneOf(["textlink", "backlink"]),
  text: PropTypes.string,
  atomsIconsSystemAtomsIconsSystem: PropTypes.string,
};
