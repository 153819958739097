/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  className: any;
  vector: string;
}

export const Vector = ({ className, vector = "/img/vector-3-1.svg" }: Props): JSX.Element => {
  return <img className={`vector ${className}`} alt="Vector" src={vector} />;
};

Vector.propTypes = {
  vector: PropTypes.string,
};
