/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const ChevronLeft = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`chevron-left ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M14.71 6.71047C14.32 6.32047 13.69 6.32047 13.3 6.71047L8.70998 11.3005C8.31998 11.6905 8.31998 12.3205 8.70998 12.7105L13.3 17.3005C13.69 17.6905 14.32 17.6905 14.71 17.3005C15.1 16.9105 15.1 16.2805 14.71 15.8905L10.83 12.0005L14.71 8.12047C15.1 7.73047 15.09 7.09047 14.71 6.71047Z"
        fill="#49D6B7"
      />
    </svg>
  );
};
