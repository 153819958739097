/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  className: any;
  divClassName: any;
  text: string;
}

export const Cta = ({ className, divClassName, text = "Jetzt 30 Tage kostenlos testen *" }: Props): JSX.Element => {
  return (
    <div className={`CTA ${className}`}>
      <p className={`jetzt-tage ${divClassName}`}>{text}</p>
    </div>
  );
};

Cta.propTypes = {
  text: PropTypes.string,
};
