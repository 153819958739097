/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  className: any;
  atomsIconsSystem: string;
}

export const AtomsIconsSystem = ({
  className,
  atomsIconsSystem = "/img/atoms-icons-system-chevron-down-2.svg",
}: Props): JSX.Element => {
  return <img className={`atoms-icons-system ${className}`} alt="Atoms icons system" src={atomsIconsSystem} />;
};

AtomsIconsSystem.propTypes = {
  atomsIconsSystem: PropTypes.string,
};
