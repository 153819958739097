/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { StyleRounded1 } from "../../icons/StyleRounded1";
import "./style.css";

interface Props {
  property1: "variant-3" | "default";
  className: any;
  icon: JSX.Element;
}

export const PromoBot = ({
  property1,
  className,
  icon = <StyleRounded1 className="chat-bubble" color="white" />,
}: Props): JSX.Element => {
  return (
    <div className={`promo-bot property-1-${property1} ${className}`}>
      {property1 === "default" && <>{icon}</>}

      {property1 === "variant-3" && (
        <>
          <div className="frame-46">
            <div className="title">Fragen?</div>
            <div className="title-2">Wir helfen gerne!</div>
          </div>
          <StyleRounded1 className="chat-bubble" color="white" />
        </>
      )}
    </div>
  );
};

PromoBot.propTypes = {
  property1: PropTypes.oneOf(["variant-3", "default"]),
};
