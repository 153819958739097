/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const StyleRounded1 = ({ color = "#29293A", className }: Props): JSX.Element => {
  return (
    <svg
      className={`style-rounded-1 ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 39 40"
      width="39"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M32.5 3.75H6.5C4.7125 3.75 3.25 5.2125 3.25 7V36.25L9.75 29.75H32.5C34.2875 29.75 35.75 28.2875 35.75 26.5V7C35.75 5.2125 34.2875 3.75 32.5 3.75Z"
        fill={color}
      />
      <path
        className="path"
        d="M32.5 3.75H6.5C4.7125 3.75 3.25 5.2125 3.25 7V36.25L9.75 29.75H32.5C34.2875 29.75 35.75 28.2875 35.75 26.5V7C35.75 5.2125 34.2875 3.75 32.5 3.75Z"
        fill="black"
        fillOpacity="0.54"
      />
      <path
        className="path"
        d="M32.5 3.75H6.5C4.7125 3.75 3.25 5.2125 3.25 7V36.25L9.75 29.75H32.5C34.2875 29.75 35.75 28.2875 35.75 26.5V7C35.75 5.2125 34.2875 3.75 32.5 3.75Z"
        fill="black"
        fillOpacity="0.54"
      />
      <path
        className="path"
        d="M32.5 3.75H6.5C4.7125 3.75 3.25 5.2125 3.25 7V36.25L9.75 29.75H32.5C34.2875 29.75 35.75 28.2875 35.75 26.5V7C35.75 5.2125 34.2875 3.75 32.5 3.75Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

StyleRounded1.propTypes = {
  color: PropTypes.string,
};
