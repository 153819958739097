/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  className: any;
  iconsGeneral: string;
}

export const IconsGeneral = ({ className, iconsGeneral = "/img/icons-24-general-edit-4.svg" }: Props): JSX.Element => {
  return <img className={`icons-general ${className}`} alt="Icons general" src={iconsGeneral} />;
};

IconsGeneral.propTypes = {
  iconsGeneral: PropTypes.string,
};
