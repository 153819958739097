/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Close7 } from "../../icons/Close7";
import { IconsGeneral } from "../IconsGeneral";
import "./style.css";

interface Props {
  status: "editiermodus" | "default";
  device: "desktop" | "mobile";
  className: any;
  iconsGeneralIconsGeneral: string;
  mask: string;
}

export const KontaktdatenAnwalt = ({
  status,
  device,
  className,
  iconsGeneralIconsGeneral = "/img/icons-24-general-edit-3.svg",
  mask = "/img/mask-5.svg",
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    status: status || "default",
    device: device || "mobile",
  });

  return (
    <div className={`kontaktdaten-anwalt ${state.status} device-3-${state.device} ${className}`}>
      <div className="div">
        {state.status === "editiermodus" && (
          <>
            <div className="apfelbaumstr">Ihre Daten</div>
            <Close7 className="close" color="#29293A" />
          </>
        )}

        {state.status === "default" && (
          <>
            <div className="frame-2">
              <div className="frame-3">
                <div className="apfelbaumstr-2">Rechtsanwältin jur. Dipl.-Jur.</div>
                <div className="apfelbaumstr-3">Iris Apfel</div>
              </div>
              <div className="frame-4">
                <div className="apfelbaumstr-4">Kanzlei</div>
                <p className="p">Iris Apfel Rechtsanwälte und Partnergesellschaft mbH</p>
              </div>
            </div>
            <IconsGeneral className="icons-24-general-edit" iconsGeneral={iconsGeneralIconsGeneral} />
          </>
        )}
      </div>
      <div className="frame-5">
        <div className="frame-6">
          <div className="apfelbaumstr-5">
            {state.status === "default" && <>Apfelbaumstr. 2</>}

            {state.status === "editiermodus" && <>Anrede &amp; Titel</>}
          </div>
          <div className="apfelbaumstr-6">
            {state.status === "default" && <>80222 Obststadt</>}

            {state.status === "editiermodus" && <div className="apfelbaumstr-7">Rechtsanwältin jur. Dipl.-Jur.</div>}
          </div>
        </div>
        <div className="frame-7">
          {state.status === "default" && (
            <>
              <div className="apfelbaumstr-4">Kontakt</div>
              <div className="frame-8">
                <img className="img" alt="Vector" src="/img/vector-2.svg" />
                <div className="text-wrapper-2">irisundpartneranwälte.de</div>
              </div>
              <div className="frame-8">
                <img className="mask" alt="Mask" src={mask} />
                <div className="text-wrapper-2">iris.apfel@apfel.de</div>
              </div>
              <div className="frame-8">
                <img className="mask-2" alt="Mask" src="/img/mask-4.svg" />
                <p className="text-wrapper-3">+49 162 24 22 2222</p>
              </div>
            </>
          )}

          {state.status === "editiermodus" && (
            <>
              <div className="text-wrapper-4">Vorname, Nachname</div>
              <div className="apfelbaumstr-wrapper">
                <div className="apfelbaumstr-7">Iris Apfel</div>
              </div>
            </>
          )}
        </div>
      </div>
      {state.status === "editiermodus" && (
        <>
          <div className="frame-9">
            <div className="apfelbaumstr-4">Kanzlei</div>
            {state.device === "mobile" && (
              <div className="frame-10">
                <div className="div-2">
                  <div className="text-wrapper-4">Kanzleiname</div>
                  <div className="apfelbaumstr-wrapper">
                    <p className="apfelbaumstr-8">Iris Apfel Rechtsanwälte und Partnergesellschaft mbH</p>
                  </div>
                </div>
                <div className="div-2">
                  <div className="text-wrapper-4">Straße, Hausnummer</div>
                  <div className="apfelbaumstr-wrapper">
                    <div className="apfelbaumstr-7">Apfelbaumstr. 2</div>
                  </div>
                </div>
                <div className="frame-11">
                  <div className="div-2">
                    <div className="text-wrapper-4">PLZ</div>
                    <div className="div-wrapper">
                      <div className="apfelbaumstr-7">80222</div>
                    </div>
                  </div>
                  <div className="frame-12">
                    <div className="text-wrapper-4">Stadt</div>
                    <div className="frame-13">
                      <div className="apfelbaumstr-7">Obststadt</div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {state.device === "desktop" && (
              <>
                <div className="div-2">
                  <div className="text-wrapper-5">Kanzleiname</div>
                  <div className="frame-14">
                    <p className="apfelbaumstr-9">Iris Apfel Rechtsanwälte und Partnergesellschaft mbH</p>
                  </div>
                </div>
                <div className="frame-15">
                  <div className="div-2">
                    <div className="text-wrapper-4">Straße, Hausnummer</div>
                    <div className="apfelbaumstr-wrapper">
                      <div className="apfelbaumstr-7">Apfelbaumstr. 2</div>
                    </div>
                  </div>
                  <div className="frame-16">
                    <div className="div-2">
                      <div className="text-wrapper-4">PLZ</div>
                      <div className="div-wrapper">
                        <div className="apfelbaumstr-7">80222</div>
                      </div>
                    </div>
                    <div className="frame-12">
                      <div className="text-wrapper-4">Stadt</div>
                      <div className="frame-13">
                        <div className="apfelbaumstr-7">Obststadt</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="frame-17">
            <div className="apfelbaumstr-4">Kontakt</div>
            <div className="frame-18">
              {state.device === "mobile" && (
                <>
                  <div className="frame-8">
                    <img className="img" alt="Vector" src="/img/vector-1.svg" />
                    <div className="frame-12">
                      <div className="text-wrapper-4">Kanzlei Homepage</div>
                      <div className="frame-13">
                        <div className="apfelbaumstr-10">irisundpartneranwälte.de</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-8">
                    <img className="mask" alt="Mask" src="/img/mask-3.svg" />
                    <div className="frame-12">
                      <div className="text-wrapper-4">E-Mail-Addresse</div>
                      <div className="frame-13">
                        <div className="apfelbaumstr-10">iris.apfel@apfel.de</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-8">
                    <img className="mask-2" alt="Mask" src="/img/mask-2.svg" />
                    <div className="frame-12">
                      <div className="text-wrapper-4">Telefonnummer</div>
                      <div className="frame-13">
                        <div className="apfelbaumstr-10">+49</div>
                        <div className="apfelbaumstr-10">162 24 22 2222</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-8">
                    <div className="ellipse" />
                    <div className="frame-12">
                      <div className="text-wrapper-4">Faxnummer</div>
                      <div className="frame-19">
                        <div className="apfelbaumstr-11">Faxnummer</div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {state.device === "desktop" && (
                <>
                  <div className="frame-20">
                    <div className="frame-21">
                      <img className="img" alt="Vector" src="/img/vector-1.svg" />
                      <div className="frame-12">
                        <div className="text-wrapper-4">Kanzlei Homepage</div>
                        <div className="frame-13">
                          <div className="apfelbaumstr-10">irisundpartneranwälte.de</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-21">
                      <img className="mask" alt="Mask" src="/img/mask-3.svg" />
                      <div className="frame-12">
                        <div className="text-wrapper-4">E-Mail-Addresse</div>
                        <div className="frame-13">
                          <div className="apfelbaumstr-10">iris.apfel@apfel.de</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-22">
                    <div className="frame-21">
                      <img className="mask-2" alt="Mask" src="/img/mask-2.svg" />
                      <div className="frame-12">
                        <div className="text-wrapper-4">Telefonnummer</div>
                        <div className="frame-13">
                          <div className="apfelbaumstr-10">+49</div>
                          <div className="apfelbaumstr-10">162 24 22 2222</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-21">
                      <div className="ellipse" />
                      <div className="frame-12">
                        <div className="text-wrapper-4">Faxnummer</div>
                        <div className="frame-19">
                          <div className="apfelbaumstr-11">Faxnummer</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className="frame-23"
            onClick={() => {
              dispatch("click");
            }}
          >
            <div className="apfelbaumstr-12">Änderungen speichern</div>
          </div>
        </>
      )}
    </div>
  );
};

function reducer(state: any, action: any) {
  switch (action) {
    case "click":
      return {
        ...state,
        status: "default",
      };
  }

  return state;
}

KontaktdatenAnwalt.propTypes = {
  status: PropTypes.oneOf(["editiermodus", "default"]),
  device: PropTypes.oneOf(["desktop", "mobile"]),
  iconsGeneralIconsGeneral: PropTypes.string,
  mask: PropTypes.string,
};
