/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Info1 } from "../../icons/Info1";
import { Cta } from "../Cta";
import { Headline } from "../Headline";
import { IconsGeneral } from "../IconsGeneral";
import { Vector } from "../Vector";
import "./style.css";

interface Props {
  status: "teaser" | "blocked" | "editierbar";
  device: "desktop" | "mobile";
  className: any;
  text: string;
}

export const Fachanwaltschaft = ({ status, device, className, text = "Rechtecheck PRO" }: Props): JSX.Element => {
  return (
    <div className={`fachanwaltschaft device-10-${device} ${status} ${className}`}>
      {(status === "editierbar" || (device === "mobile" && status === "blocked")) && (
        <div className="frame-36">
          {status === "blocked" && (
            <>
              <div className="frame-37">
                <div className="apfelbaumstr-17">Ihre Fachanwaltschaft</div>
              </div>
              <div className="frame-38">
                <div className="rechtecheck-PRO">{text}</div>
                <Info1 className="info" />
              </div>
            </>
          )}

          {status === "editierbar" && device === "mobile" && (
            <>
              <div className="apfelbaumstr-18">Ihre Fachanwaltschaft</div>
              <IconsGeneral
                className="icons-24-general-edit-instance"
                iconsGeneral="/img/icons-24-general-edit-7.svg"
              />
            </>
          )}

          {device === "desktop" && (
            <div className="frame-39">
              <div className="apfelbaumstr-19">Ihre Fachanwaltschaft</div>
              <IconsGeneral
                className="icons-24-general-edit-instance"
                iconsGeneral="/img/icons-24-general-edit-6.svg"
              />
            </div>
          )}
        </div>
      )}

      {status === "blocked" && device === "desktop" && (
        <>
          <div className="apfelbaumstr-19">Ihre Fachanwaltschaft</div>
          <div className="frame-40">
            <div className="rechtecheck-PRO">{text}</div>
            <Info1 className="info" />
          </div>
        </>
      )}

      {status === "teaser" && (
        <>
          <Headline
            className="headline-instance"
            device="mobile"
            hasBody={false}
            hasLink={false}
            text="Fachanwaltschaft gratis hinzufügen*"
            text1="Jetzt Rechtecheck PRO kostenlos kennenlernen"
          />
          <div className="frame-41">
            <div className="frame-42">
              <Vector className="vector-3" vector="/img/vector-4-3.svg" />
              <div className="text-wrapper-9">Mehr Sichtbarkeit</div>
            </div>
            <div className="frame-42">
              <Vector className="vector-3" vector="/img/vector-4-3.svg" />
              <div className="text-wrapper-9">Mehr Anfragen</div>
            </div>
          </div>
          <div className="frame-43">
            <div className="frame-44">
              <div className="apfelbaumstr-20">Ihre Fachanwaltschaft</div>
            </div>
            <div className="frame-45">
              <div className="text-wrapper-10">PRO-Account</div>
              <Info1 className="info" />
            </div>
          </div>
          <div className="subtext">
            <p className="text-wrapper-11">*50% Rabatt und Fachanwaltschaft 1 Jahr kostenlos dazubekommen</p>
            <p className="text-wrapper-12">nur 9,99€/ Monat bei jährl. Zahlweise</p>
          </div>
          <Cta
            className={`${device === "desktop" ? "class" : "class-2"}`}
            divClassName="CTA-instance"
            text="Jetzt kostenlos testen"
          />
        </>
      )}
    </div>
  );
};

Fachanwaltschaft.propTypes = {
  status: PropTypes.oneOf(["teaser", "blocked", "editierbar"]),
  device: PropTypes.oneOf(["desktop", "mobile"]),
  text: PropTypes.string,
};
