import React from "react";
import { ChatBubble } from "../../components/ChatBubble";
import { Cta } from "../../components/Cta";
import { Fachanwaltschaft } from "../../components/Fachanwaltschaft";
import { FaqsMobile } from "../../components/FaqsMobile";
import { Headline } from "../../components/Headline";
import { KontaktdatenAnwalt } from "../../components/KontaktdatenAnwalt";
import { Link } from "../../components/Link";
import { PromoBot } from "../../components/PromoBot";
import { Rechtsgebiete } from "../../components/Rechtsgebiete";
import { Vector } from "../../components/Vector";
import "./style.css";

export const MEintrag = (): JSX.Element => {
  return (
    <div className="m-eintrag">
      <div className="text-wrapper-14">kostenloses Profil erstellen</div>
      <div className="frame-48">
        <div className="frame-49">
          <Headline
            className="design-component-instance-node"
            device="mobile"
            hasBody={false}
            text={
              <>
                Bitte prüfen Sie Ihre bei uns <br />
                gespeicherten Daten
              </>
            }
          />
          <div className="frame-50">
            <KontaktdatenAnwalt
              className="design-component-instance-node-2"
              device="mobile"
              iconsGeneralIconsGeneral="/img/icons-24-general-edit-1.svg"
              mask="/img/mask-1.svg"
              status="default"
            />
            <Rechtsgebiete
              className="design-component-instance-node-2"
              iconsGeneralIconsGeneral="/img/icons-24-general-edit.svg"
              status="default"
            />
            <Fachanwaltschaft
              className="design-component-instance-node"
              device="mobile"
              status="blocked"
              text="Rechtecheck Pro"
            />
          </div>
          <div className="frame-51">
            <div className="title-wrapper">
              <div className="title-3">Eintrag nur verifizieren</div>
            </div>
          </div>
          <div className="frame-52">
            <div className="frame-53">
              <p className="text-wrapper-15">Entdecken Sie die Vorteile eines Pro-Accounts</p>
              <Cta
                className="CTA-2"
                divClassName="CTA-3"
                text="Eintrag verifizieren und Pro-Account kostenlos testen"
              />
            </div>
            <p className="rechtecheck-pro-mehr">
              <span className="text-wrapper-16">
                Rechtecheck Pro
                <br />
              </span>
              <span className="text-wrapper-17">
                Mehr Mandats-Anfragen,
                <br />
                weniger Arbeit.
              </span>
            </p>
            <div className="frame-54">
              <div className="frame-55">
                <Vector className="vector-instance" vector="/img/vector-4.svg" />
                <p className="text-wrapper-18">Mehr qualifizierte Mandats-Anfragen durch Premium-Eintrag</p>
              </div>
              <div className="frame-55">
                <Vector className="vector-instance" vector="/img/vector-4.svg" />
                <p className="text-wrapper-18">Erfolgsaussichten der Mandatierung schnell beurteilen</p>
              </div>
              <div className="frame-55">
                <Vector className="vector-instance" vector="/img/vector-4.svg" />
                <p className="text-wrapper-18">Bis zu 80% Zeitersparnis pro Mandat durch automatisierte Workflows</p>
              </div>
              <div className="frame-55">
                <Vector className="vector-instance" vector="/img/vector-4.svg" />
                <p className="text-wrapper-18">Sofort einsatzbereit, leicht zu bedienen</p>
              </div>
            </div>
            <Link
              atomsIconsSystemAtomsIconsSystem="/img/atoms-icons-system-chevron-down-1.svg"
              className="design-component-instance-node"
              text="Jetzt alle Pro-Vorteile ansehen"
              type="textlink"
            />
            <Cta className="CTA-4" divClassName="CTA-5" text="Eintrag verifizieren und kostenlos testen" />
            <img className="image" alt="Image" src="/img/image-47-1.png" />
          </div>
        </div>
        <div className="group">
          <div className="overlap-group">
            <div className="frame-56">
              <div className="frame-57">
                <div className="title-4">Fragen?</div>
                <p className="title-5">Wir sind im Chat für Sie da.</p>
              </div>
            </div>
            <PromoBot className="promo-bot-instance" icon={<ChatBubble style="rounded" />} property1="default" />
          </div>
        </div>
        <FaqsMobile
          atomsDividersAtomsDividersClassName="faqs-mobile-instance"
          atomsIconsSystemAtomsIconsSystem="/img/icon-5.svg"
          atomsIconsSystemAtomsIconsSystem1="/img/icon-3.svg"
          atomsIconsSystemAtomsIconsSystem2="/img/icon-2.svg"
          atomsIconsSystemAtomsIconsSystem3="/img/icon-1.svg"
          atomsIconsSystemImg="/img/icon-4.svg"
          className="design-component-instance-node-2"
          linkAtomsIconsSystemAtomsIconsSystem="/img/atoms-icons-system-chevron-down.svg"
        />
        <div className="placeholder">
          <div className="overlap">
            <div className="image-2" />
            <div className="text-wrapper-19">Footer</div>
          </div>
        </div>
      </div>
      <div className="frame-58">
        <div className="frame-59">
          <div className="frame-60">
            <div className="text-wrapper-20">Rechtcheck</div>
            <div className="text-wrapper-21">by LegalCloud</div>
          </div>
          <div className="frame-61">
            <div className="frame-62">
              <div className="frame-63">
                <div className="text-wrapper-22">Login</div>
              </div>
            </div>
            <img className="image-3" alt="Image" src="/img/image-47.png" />
          </div>
        </div>
        <img className="line-2" alt="Line" src="/img/line-35.svg" />
      </div>
    </div>
  );
};
