/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { StyleFilled1 } from "../../icons/StyleFilled1";
import { StyleRounded1 } from "../../icons/StyleRounded1";
import { StyleSharp1 } from "../../icons/StyleSharp1";
import { StyleTwoTone1 } from "../../icons/StyleTwoTone1";
import "./style.css";

interface Props {
  style: "sharp" | "rounded" | "filled" | "two-tone" | "outlined";
}

export const ChatBubble = ({ style }: Props): JSX.Element => {
  return (
    <>
      {style === "rounded" && <StyleRounded1 className="instance-node-2" color="#29293A" />}

      {["filled", "outlined"].includes(style) && <StyleFilled1 className="instance-node-2" />}

      {style === "sharp" && <StyleSharp1 className="instance-node-2" />}

      {style === "two-tone" && <StyleTwoTone1 className="instance-node-2" />}
    </>
  );
};

ChatBubble.propTypes = {
  style: PropTypes.oneOf(["sharp", "rounded", "filled", "two-tone", "outlined"]),
};
