/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Close7 } from "../../icons/Close7";
import { IconsGeneral } from "../IconsGeneral";
import { SchwerpunkteDefault } from "../SchwerpunkteDefault";
import "./style.css";

interface Props {
  status: "two-search-active" | "one-editiermodus" | "three-changed" | "default";
  className: any;
  iconsGeneralIconsGeneral: string;
}

export const Rechtsgebiete = ({
  status,
  className,
  iconsGeneralIconsGeneral = "/img/icons-24-general-edit-5.svg",
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    status: status || "default",
  });

  return (
    <div className={`rechtsgebiete ${className}`}>
      <div className={`frame-24 status-5-${state.status}`}>
        <div className="frame-25">
          {["one-editiermodus", "three-changed", "two-search-active"].includes(state.status) && (
            <>
              <div className="apfelbaumstr-13">Ihre Rechtsgebiete</div>
              <Close7 className="close-7" color="#29293A" />
            </>
          )}

          {state.status === "default" && (
            <>
              <div className="apfelbaumstr-14">Rechtsgebiete</div>
              <IconsGeneral className="icons-general-instance" iconsGeneral={iconsGeneralIconsGeneral} />
            </>
          )}
        </div>
        {state.status === "default" && (
          <>
            <div className="frame-26">
              <SchwerpunkteDefault className="schwerpunkte-default-s" text="Sozialrecht" />
              <SchwerpunkteDefault className="schwerpunkte-default-s" text="Wirtschaftsrecht" />
            </div>
            <div className="frame-26">
              <SchwerpunkteDefault className="schwerpunkte-default-s" text="Asylrecht" />
              <SchwerpunkteDefault className="schwerpunkte-default-s" text="Unterhaltsrecht" />
            </div>
          </>
        )}

        {["one-editiermodus", "three-changed", "two-search-active"].includes(state.status) && (
          <>
            <div className="frame-27">
              <div className="frame-28">
                <div className="frame-wrapper">
                  <div className="frame-29">
                    <Close7 className="close-2" color="white" />
                    <div className="text-wrapper-6">Sozialrecht</div>
                  </div>
                </div>
                <div className="frame-wrapper">
                  <div className="frame-29">
                    <Close7 className="close-2" color="white" />
                    <div className="text-wrapper-6">Wirtschaftsrecht</div>
                  </div>
                </div>
              </div>
              <div className="frame-30">
                <div className="schwerpunkte-default-2">
                  <div className="frame-29">
                    <Close7 className="close-2" color="white" />
                    <div className="text-wrapper-6">Asylrecht</div>
                  </div>
                </div>
                {state.status === "three-changed" && (
                  <div className="frame-wrapper">
                    <div className="frame-29">
                      <Close7 className="close-2" color="white" />
                      <div className="text-wrapper-6">Unterhaltsrecht</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="frame-31">
              <div className="apfelbaumstr-15">
                {["one-editiermodus", "three-changed"].includes(state.status) && (
                  <p className="text-wrapper-23">Fügen Sie weitere Rechtsgebiete hinzu</p>
                )}

                {state.status === "two-search-active" && (
                  <>
                    <p className="apfelbaumstr-14">Fügen Sie weitere Rechtsgebiete hinzu</p>
                    <div className="frame-32">
                      <div className="frame-33">
                        <div className="text-wrapper-7">Unte</div>
                        <Close7 className="close-1" color="#545454" />
                        <img className="line" alt="Line" src="/img/line-68.svg" />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="frame-34"
                onClick={() => {
                  dispatch("click");
                }}
              >
                {["one-editiermodus", "three-changed"].includes(state.status) && (
                  <div className="frame-33">
                    <div className="text-wrapper-8">Nach Rechtsgebiet suchen</div>
                    <div className="search" />
                  </div>
                )}

                {state.status === "two-search-active" && (
                  <>
                    <SchwerpunkteDefault
                      className="schwerpunkte-default-s-instance"
                      divClassName="schwerpunkte-default-instance"
                      text="Unternehmensrecht &amp; Betriebsnachfolge"
                    />
                    <SchwerpunkteDefault
                      className="instance-node"
                      divClassName="schwerpunkte-default-instance"
                      onClick={() => {
                        dispatch("click_363");
                      }}
                      text="Unterhaltsrecht"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="frame-35">
              <div className="apfelbaumstr-16">Speichern</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function reducer(state: any, action: any) {
  if (state.status === "one-editiermodus") {
    switch (action) {
      case "click":
        return {
          status: "two-search-active",
        };
    }
  }

  if (state.status === "two-search-active") {
    switch (action) {
      case "click_363":
        return {
          status: "three-changed",
        };
    }
  }

  return state;
}

Rechtsgebiete.propTypes = {
  status: PropTypes.oneOf(["two-search-active", "one-editiermodus", "three-changed", "default"]),
  iconsGeneralIconsGeneral: PropTypes.string,
};
