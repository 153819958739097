/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { AtomsDividers } from "../AtomsDividers";
import { AtomsIconsSystem } from "../AtomsIconsSystem";
import { Link } from "../Link";
import "./style.css";

interface Props {
  className: any;
  atomsIconsSystemAtomsIconsSystem: string;
  linkAtomsIconsSystemAtomsIconsSystem: string;
  atomsIconsSystemImg: string;
  atomsIconsSystemAtomsIconsSystem1: string;
  atomsIconsSystemAtomsIconsSystem2: string;
  atomsIconsSystemAtomsIconsSystem3: string;
  atomsDividersAtomsDividersClassName: any;
}

export const FaqsMobile = ({
  className,
  atomsIconsSystemAtomsIconsSystem = "/img/icon.svg",
  linkAtomsIconsSystemAtomsIconsSystem = "/img/atoms-icons-system-chevron-down-4.svg",
  atomsIconsSystemImg = "/img/icon-9.svg",
  atomsIconsSystemAtomsIconsSystem1 = "/img/icon-8.svg",
  atomsIconsSystemAtomsIconsSystem2 = "/img/icon-7.svg",
  atomsIconsSystemAtomsIconsSystem3 = "/img/icon-6.svg",
  atomsDividersAtomsDividersClassName,
}: Props): JSX.Element => {
  return (
    <div className={`faqs-mobile ${className}`}>
      <div className="element-expanded">
        <div className="frame-47">
          <div className="is-this-thing-on">Was ist Rechtecheck?</div>
          <AtomsIconsSystem atomsIconsSystem={atomsIconsSystemAtomsIconsSystem} className="icon" />
        </div>
        <p className="answer">
          Rechtecheck ist eines der führenden Rechtsportale Deutschlands und die digitale Schnittstelle zwischen
          rechtsratsuchenden Verbraucher:innen und Anwaltskanzleien. Potenzielle Mandant:innen können hier nach einem
          Anwalt für ihr Rechtsgebiet suchen und eine unverbindliche Anfrage stellen. Auf rechtecheck.de finden sie
          zudem laufend aktualisierte Informationen zu vielen Rechtsgebieten.
        </p>
        <Link
          atomsIconsSystemAtomsIconsSystem={linkAtomsIconsSystemAtomsIconsSystem}
          className="link-instance"
          text=" Mehr zu Rechtecheck"
          type="textlink"
        />
      </div>
      <div className="element-expanded">
        <div className="frame-47">
          <p className="is-this-thing-on-2">Warum bin ich auf Rechtecheck gelistet?</p>
          <AtomsIconsSystem atomsIconsSystem={atomsIconsSystemImg} className="atoms-icons-system-instance" />
        </div>
        <p className="answer">
          Alle Awält:innen und Kanzleien in Deutschland, deren Daten im bundesweiten amtlichen Anwaltsverzeichnis
          hinterlegt sind, werden automatisch auf Rechtecheck gelistet. Da Sie im amtlichen Anwaltsverzeichnis vertreten
          sind, wurde aus Ihren öffentlich einsehbaren Daten ein kostenloser Eintrag auf Rechtecheck erstellt.
        </p>
      </div>
      <div className="element-expanded">
        <div className="frame-47">
          <p className="is-this-thing-on-2">Woher hat Rechtecheck meine Daten?</p>
          <AtomsIconsSystem
            atomsIconsSystem={atomsIconsSystemAtomsIconsSystem1}
            className="atoms-icons-system-instance"
          />
        </div>
        <p className="answer">
          Die Daten aller auf Rechtecheck gelisteten Anwält:innen und Kanzleien stammen aus dem bundesweiten amtlichen
          Anwaltsverzeichnis und sind öffentlich zugänglich. Außer der Kanzleiadresse und den Kontaktdaten enthält jeder
          Eintrag auf Rechtecheck auch die Rechtsgebiete.
        </p>
      </div>
      <div className="element-expanded">
        <div className="frame-47">
          <p className="is-this-thing-on-2">Kann ich meinen Eintrag löschen?</p>
          <AtomsIconsSystem
            atomsIconsSystem={atomsIconsSystemAtomsIconsSystem2}
            className="atoms-icons-system-instance"
          />
        </div>
        <p className="answer-2">
          <span className="span">Selbstverständlich. Schicken Sie einfach eine Mail an </span>
          <span className="text-wrapper-13">xxxx@rechtecheck.de</span>
          <span className="span">. Wir werden Ihren Eintrag dann umgehend von der Seite </span>
          <span className="text-wrapper-13">www.rechtecheck.de</span>
          <span className="span"> entfernen.</span>
        </p>
      </div>
      <div className="element-expanded">
        <div className="frame-47">
          <p className="is-this-thing-on-2">Welche Vorteile bietet mir die LegalCloud?</p>
          <AtomsIconsSystem
            atomsIconsSystem={atomsIconsSystemAtomsIconsSystem3}
            className="atoms-icons-system-instance"
          />
        </div>
        <p className="answer">
          LegalCloud ist eine Softwarelösung, mit der Anwält:innen und Kanzleien Anfragen effizient managen und Kunden
          skalierbar betreuen können. Durch standardisierte Kontaktformulare sowie die integrierten Workflows und
          Textvorlagen lassen sich Mandantenanfragen bis zu 80 % schneller bearbeiten. Sie können in Sekundenschnelle
          beurteilen, ob Kundenanfragen Aussicht auf eine Mandatierung haben oder nicht. LegalCloud ist Browser- sowie
          App-basiert und innerhalb von zehn Minuten einsatzbereit.
        </p>
      </div>
      <AtomsDividers atomsDividers="/img/divider-1.svg" className={atomsDividersAtomsDividersClassName} />
    </div>
  );
};

FaqsMobile.propTypes = {
  atomsIconsSystemAtomsIconsSystem: PropTypes.string,
  linkAtomsIconsSystemAtomsIconsSystem: PropTypes.string,
  atomsIconsSystemImg: PropTypes.string,
  atomsIconsSystemAtomsIconsSystem1: PropTypes.string,
  atomsIconsSystemAtomsIconsSystem2: PropTypes.string,
  atomsIconsSystemAtomsIconsSystem3: PropTypes.string,
};
