/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Info1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`info-1 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.99984 1.16675C3.77984 1.16675 1.1665 3.78008 1.1665 7.00008C1.1665 10.2201 3.77984 12.8334 6.99984 12.8334C10.2198 12.8334 12.8332 10.2201 12.8332 7.00008C12.8332 3.78008 10.2198 1.16675 6.99984 1.16675ZM7.58317 9.91675H6.4165V6.41675H7.58317V9.91675ZM7.58317 5.25008H6.4165V4.08341H7.58317V5.25008Z"
        fill="#4F4F4F"
      />
    </svg>
  );
};
